.link {
  color: white;
}

.link:hover {
  border-bottom: 1px solid white;
}

.link-social:hover {
  background-color: white;
  scale: 1.1;
  transition: 500ms all;
}

.link-social {
  scale: 1;
  transition: 500ms all;
}
